<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    class="cardDialog"
    width="500px"
    center
  >
    <div :class="[show_more ? 'show_true' : 'show_false']" class="myBox">
      <userMsg :item="itemMsg"></userMsg>
      <div v-if="show_more" class="show_box">
        <!-- 擅长业务 -->
        <div>
          <h1>{{ $t("companyGoodBusiness") }}</h1>
          <div class="green_box">
            <span v-for="(item, i) in Good_at_business" :key="i"
              >#{{ item | bussinessFormat(LOCALE) }}</span
            >
            <span v-if="Good_at_business.length == 0">-</span>
          </div>
        </div>
        <!-- 航线 -->
        <div>
          <h1>{{ $t("BussinessMajor") }}</h1>
          <div class="green_box">
            <span v-for="(item, i) in BussinessMajor" :key="i"
              >#{{ item | shippingFormat(LOCALE) }}</span
            >
            <span v-if="BussinessMajor.length == 0">-</span>
          </div>
        </div>
        <!-- 个人介绍 -->
        <div class="Personal_introduction">
          <h1>{{ $t("Personal_introduction") }}</h1>
          <p>
            {{ introduction.user_profile | textFormat }}
          </p>
        </div>
        <!-- 联系方式 -->
        <div class="lxfs">
          <div>
            <label>{{ $t("company_net") }}</label
            ><a
              class="mainColor cursor"
              @click="toWebsite(introduction.website)"
              >{{ introduction.website | textFormat }}
            </a>
          </div>
          <div>
            <label>{{ $t("wechat") }}</label
            ><span> {{ introduction.wechat | textFormat }}</span
            ><i
              @click="copyText(introduction.wechat)"
              class="el-icon-document-copy"
            ></i>
          </div>
          <div>
            <label>Facebook</label
            ><span>{{ introduction.facebook | textFormat }}</span
            ><i
              @click="copyText(introduction.facebook)"
              class="el-icon-document-copy"
            ></i>
          </div>
          <div>
            <label>{{ $t("linkedIn") }}</label
            ><span>{{ introduction.linkedin | textFormat }}</span
            ><i
              @click="copyText(introduction.linkedin)"
              class="el-icon-document-copy"
            ></i>
          </div>
          <div>
            <label>Skype </label
            ><span>{{ introduction.skype | textFormat }}</span
            ><i
              @click="copyText(introduction.skype)"
              class="el-icon-document-copy"
            ></i>
          </div>
        </div>
        <div class="clearfix">
          <div
            class="videoItem fl"
            v-for="(item, index) in introduction.video_list"
            :key="index"
          >
            <div class="img cursor img-wrap">
              <el-image
                :src="item.cover_url"
                fit="scale-down"
                class="imgitem"
              ></el-image>
              <div class="mark">
                <i
                  class="el-icon-video-play cursor"
                  @click="handleContent(item)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_footer_box">
        <el-button
          @click="ExchangeCard"
          type="primary"
          size="mini"
          class="btn_footer"
          :disabled="introduction.user_id == USER_INFO.id"
          >{{ $t("Exchange_business_cards") }}</el-button
        >
      </div>
    </div>
    <div slot="footer">
      <div class="box" @click="show_more = !show_more">
        <span>{{ show_more ? $t("packup") : $t("Expand_more") }}</span>
        <i
          :class="[
            show_more ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right',
          ]"
          class="i_rotate"
        ></i>
      </div>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </el-dialog>
</template>
<script>
import userMsg from "@/baseComponents/card/userMsg";
import { priorFormat, industryFormat } from "@/basePlugins/filters";
import videoPlay from "@/baseComponents/videoPlay";

export default {
  props: {
    hideExchange: {
      type: Boolean,
      default: true,
    },
    followedId: {
      //详细信息查看的用户id
      type: Number,
      default: null,
    },
    company_id: {
      // 交换名片   被操作者id
      type: String | Number,
      default: null,
    },
    follow_type: {
      // 1是公司  2是用户
      type: String | Number,
      default: 2,
    },
  },
  components: {
    userMsg,
    videoPlay,
  },
  watch: {
    followedId() {
      if (this.followedId) {
        this.getCardInfo();
      }
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      show_more: false,
      itemMsg: {},
      Good_at_business: [
        //优势业务
      ],
      BussinessMajor: [],
      introduction: {},
      emit_followed_user_id: "",
      emit_company_id: "",
    };
  },
  mounted() {
    this.$GLOBALEVENT.$on("OPENUSERCard", (row) => {
      this.show_more = false;
      this.dialogFormVisible = row.show;
      this.emit_followed_user_id = row.followed_user_id;
      this.emit_company_id = row.emit_company_id;
    });
  },
  destroyed() {
    this.$GLOBALEVENT.$off("OPENUSERCard");
  },
  methods: {
    openCardDialog(row) {
      this.show_more = false;
      this.dialogFormVisible = row.show;
      this.emit_followed_user_id = row.followed_user_id;
      this.emit_company_id = row.emit_company_id;
    },
    async ExchangeCard() {
      let params = {
        company_id: this.company_id || this.emit_company_id,
        visit_company_id: this.$store.state.baseStore.userInfo.company_id,
        visit_user_id: this.$store.state.baseStore.userInfo.id,
        followed_user_id: this.followedId || this.emit_followed_user_id,
        follow_type: this.follow_type,
        source: this.PJSource,
      };
      params.is_vip = this.USER_INFO.is_vip;
      return;
      let exchangeCard = await this.$store.dispatch(
        "register/exchangeCard",
        params
      );
      if (exchangeCard && exchangeCard.success) {
        this.$message.success(this.$t("exchangeCard_success"));
      }
      if (exchangeCard && exchangeCard.message == "already exist") {
        this.$message.warning(this.$t("card_exit"));
      }
    },
    handleContent(item) {
      this.$refs.vidoePlay.startPlay(item.aliyun_id);
    },
    copyText(text) {
      if (!text) return;
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
    toWebsite(url) {
      let _url;
      if (url.indexOf("http") != -1) {
        _url = url;
      } else {
        _url = `http://${url}`;
      }
      window.open(_url);
    },
    async getCardInfo() {
      let params = {
        user_id: this.followedId,
      };
      if (this.followedId != this.USER_INFO.id) {
        params.followed_user_id = this.USER_INFO.id;
      }
      try {
        let result = await this.$store.dispatch(
          "baseConsole/getCollectionDetail",
          params
        );
        if (result.success) {
          let data = result.data;
          let obj = JSON.parse(data.foreign_info);
          this.introduction = Object.assign(data, obj);
          this.Good_at_business = data.business_classification;
          this.BussinessMajor = data.shipping_line;
          this.itemMsg = {
            name: priorFormat(
              data.user_name_en,
              data.user_name_zh,
              this.LOCALE
            ),
            company:
              (obj && obj.company_name) ||
              priorFormat(
                data.company_name_en,
                data.company_name_zh,
                this.LOCALE
              ),
            job: priorFormat(data.job_title_en, data.job_title_zh, this.LOCALE),
            address:
              (obj && obj.address) ||
              priorFormat(data.address_en, data.address_zh, this.LOCALE),
            country: (obj && obj.country) || data.country,
            city: (obj && obj.city) || data.city,
            mobile: data.mobile || this.USER_INFO.mobile,
            email: data.email,
            industry: obj && obj.Industry_id, // || industryFormat(data.industry, this.LOCALE),
            region_en: data.region_en,
            region_zh: data.region_zh,
            avatar: data.avatar,
          };
        }
        if (!this.itemMsg.industry) {
          //新公司的 业务要换一个接口取值
          if (!this.USER_INFO.company_id) {
            return;
          }
          let data = await this.$store.dispatch(
            "baseConsole/company_getBussinessInfo",
            {
              company_id: this.USER_INFO.company_id,
            }
          );
          this.itemMsg.industry = data.data.company_industry;
        }

        this.loading = false;
      } catch (e) {}
    },
  },
};
</script>
<style lang="less" scoped>
.show_true {
  height: 565px;
}
.show_false {
  height: 358px;
  overflow-y: scroll;
}
.cardDialog {
  // /deep/ .el-dialog__header {
  //   height: 0;
  // }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .myBox {
    padding: 0 40px;
    padding-top: 15px;
    overflow-y: scroll;
    .show_box {
      h1 {
        font-size: 14px;
        font-weight: bold;
        color: #1f292e;
        padding-top: 21px;
        padding-bottom: 12px;
      }
      .green_box {
        display: flex;
        flex-wrap: wrap;
        span {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #1ea89d;
          line-height: 17px;
          padding-left: 10px;
        }
      }
      .Personal_introduction {
        p {
          font-size: 12px;
          font-weight: 400;
          color: #1f292e;
          line-height: 17px;
        }
      }
      .lxfs {
        padding-top: 20px;
        div {
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          label {
            width: 78px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            margin-right: 24px;
          }
          span {
            font-size: 14px;
            font-weight: 800;
            color: #333333;
          }
          i {
            margin-left: 5px;
            font-size: 24px;
            cursor: pointer;
            color: #539cff;
          }
        }
      }
    }
  }
  .btn_footer_box {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    .btn_footer {
      width: 321px;
      // background-color: #1890FF;
      margin: auto;
    }
  }
  .videoItem {
    position: relative;
    margin-right: 10px;
    width: 120px;
    .img-wrap {
      position: relative;
      .imgitem {
        height: 80px;
      }
      .mark {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
        .el-icon-video-play {
          font-size: 30px;
          color: #aaa;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .mark:hover {
        background: rgba(0, 0, 0, 0.6);
      }
      .mark:hover .el-icon-video-play {
        color: #fff;
      }
    }
  }
  .i_rotate {
    transform: rotate(90deg);
  }
  /deep/ .el-dialog__footer {
    border-top: 1px solid #d8d8d8;
    height: 55px;
    padding: 16px;
    cursor: pointer;
    span,
    i {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #909399;
    }
  }
}
</style>